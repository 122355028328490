import React, { useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Alert, Form, Button } from 'react-bootstrap';
import PhoneAuth from '../context/PhoneAuth';
import { GoogleButton } from 'react-google-button';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const Signin = () => {
  const { googleSignIn, user } = UserAuth();
  const navigate = useNavigate();
  const [signInError, setSignInError] = useState(null);

  useEffect(() => {
    if (user != null) {
      navigate('/studio');
    }
  }, [user, navigate]);



  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      setSignInError(error.message);
    }
  };


  return (
    <div className="text-dark justify-content-center align-items-center " >

      <Container className='mt-4 '>

        <Row>


          {/* Sign-in Form */}
          <Col md={12} className='bg-light mt-4 p-4 rounded'>
            <PhoneAuth />
            <GoogleButton className="mt-4 mb-4" style={{ display: 'block', margin: 'auto' }} onClick={handleGoogleSignIn} />
          </Col>
          {/* Sign-up Form */}


        </Row>
      </Container>
    </div>
  );
};

export default Signin;
