import React, { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import { collection, onSnapshot, doc, deleteDoc, query, where, orderBy, updateDoc, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { serverTimestamp } from 'firebase/firestore';
import { Link, useLocation } from 'react-router-dom';
// Assuming the rest of your component is defined here
import { Camera, Copy, Download, Youtube } from 'react-bootstrap-icons';

function Studio() {
    const { user, logOut, userData } = UserAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingParagprah, setIsLoadingParagprah] = useState(false);
    const [category, setCategory] = useState('');
    const [ideaOrTopic, setIdeaOrTopic] = useState('');
    const [titles, setTitles] = useState([]);
    const [paragraphs, setParagrpahs] = useState([]);
    const [inputError, setInputError] = useState('');
    const [editingId, setEditingId] = useState(null); // Track which title is being edited
    const [editedText, setEditedText] = useState(''); // Hold the edited text
    const [selectedId, setSelectedId] = useState(null);
    const [loadingGraphicId, setLoadingGraphicId] = useState(null);
    const [isGeneratingVideo, setIsGeneratingVideo] = useState(false);
    const [voiceOption, setVoiceOption] = useState('onyx');

    const [countdown, setCountdown] = useState(120); // Initialize with 120 seconds (2 minutes)
    const [countdownIntervalId, setCountdownIntervalId] = useState(null);

    const [copiedText, setCopiedText] = useState('');

    const copyText = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedText(text);
            // Optionally, alert the user that the text has been copied
            alert('Script copied to clipboard!');
        });
    };



    const [videos, setVideos] = useState([]);

    // Check if userData exists and has balance
    const balance = userData && userData.balance ? userData.balance : 0;

    useEffect(() => {
        const fetchUserVideos = async () => {
            try {
                const q = query(collection(db, 'videos'), where('author_Id', '==', user.uid), orderBy('timestamp', 'desc'));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const userVideos = [];
                    querySnapshot.forEach((doc) => {
                        const { author_Id, timestamp, ...videoData } = doc.data();
                        const formattedTimestamp = timestamp ? timestamp.toDate() : null; // Check if timestamp exists
                        userVideos.push({ ...videoData, timestamp: formattedTimestamp }); // Convert Firestore Timestamp to JavaScript Date object if it exists
                    });
                    setVideos(userVideos);
                });

                return unsubscribe;
            } catch (error) {
                console.error('Error fetching user videos:', error);
            }
        };

        fetchUserVideos();

        return () => { };
    }, [user.uid]);


    // console.log(user.uid)
    // Cleanup on component unmount
    useEffect(() => {
        return () => {
            if (countdownIntervalId !== null) {
                clearInterval(countdownIntervalId);
            }
        };
    }, [countdownIntervalId]);

    // Convert countdown seconds to a string format (e.g., "1:59")
    const formatCountdown = () => {
        const minutes = Math.floor(countdown / 60);
        const seconds = countdown % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };



    const categories = [
        "Autos & Vehicles", "Comedy", "Education", "Entertainment",
        "Film & Animation", "Gaming", "How to & Style", "Music",
        "News & Politic", "Nonprofits & Activism", "People & Blogs",
        "Pets & Animals", "Science & Technology", "Sports", "Travel & Events"
    ];

    const handleCustomChange = (paragraphId) => {
        setParagrpahs(currentParagraphs =>
            currentParagraphs.map(paragraph =>
                paragraph.id === paragraphId ? { ...paragraph, custom: !paragraph.custom } : paragraph
            )
        );
    };

    const handleCustomPromptChange = (paragraphId, newPrompt) => {
        setParagrpahs(currentParagraphs =>
            currentParagraphs.map(paragraph =>
                paragraph.id === paragraphId ? { ...paragraph, customPrompt: newPrompt } : paragraph
            )
        );
    };




    const handleVoiceOptionChange = (event) => {

        setVoiceOption(event.target.value);
    };

    const validateInput = (input) => {
        const regex = /^[a-zA-Z\s]*$/;
        if (!regex.test(input) || input.split(' ').length > 10 || input.length > 200) {
            setInputError('Input must be text only, up to 10 words, and max 100 characters.');
            return false;
        }
        setInputError('');
        return true;
    };


    const handleGenerateGraphic = (paragraphId, paragraphText, custom, customPrompt) => {
        const selectedTitleObj = titles.find(title => title.id === selectedId);

        // console.log(selectedTitleObj)
        if (!category) {
            console.error('Category is required');
            return;
        }
        if (!validateInput(ideaOrTopic)) {
            console.error('Idea or topic validation failed');
            return;
        }
        if (!selectedTitleObj || !selectedTitleObj.title) {
            console.error('Selected title is invalid');
            return;
        }
        if (!paragraphText) {
            console.error('Paragraph is required');
            return;
        }

        // Check if custom is true and customPrompt has at least 3 non-space characters
        if (custom && customPrompt.trim().length >= 3) {
            paragraphText = customPrompt.trim();
        }

        setLoadingGraphicId(paragraphId);
        // Mark the paragraph as loading
        setParagrpahs(currentParagraphs =>
            currentParagraphs.map(paragraph =>
                paragraph.id === paragraphId ? { ...paragraph, isLoading: true } : paragraph
            )
        );

        fetch('https://shorts-backend-api-2.calmforest-0de75ae2.centralus.azurecontainerapps.io/gen_graphic/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
            body: JSON.stringify({ category: category, ideaOrTopic: ideaOrTopic, title: selectedTitleObj.title, paragraph: paragraphText, custom: custom, user: user.uid }),
        })
            .then(response => response.json())
            .then(data => {
                const imageUrl = data.url;
                setParagrpahs(currentParagraphs =>
                    currentParagraphs.map(paragraph =>
                        paragraph.id === paragraphId ? { ...paragraph, imageUrl: imageUrl, isLoading: false } : paragraph
                    )
                );
            })
            .catch(error => {
                console.error('Error generating graphic:', error);
                setInputError('Failed to generate graphic. Please try again.');
                setParagrpahs(currentParagraphs =>
                    currentParagraphs.map(paragraph =>
                        paragraph.id === paragraphId ? { ...paragraph, isLoading: false } : paragraph
                    )
                );
            });
    };




    const handleGenerateTitles = () => {
        if (!category || !validateInput(ideaOrTopic)) return; // Ensure category is selected and idea/topic is valid


        setSelectedId(null);

        setIsLoading(true); // Start loading
        setTitles([]); // Clear previous titles

        // Adjust your API endpoint and method as necessary
        fetch('https://shorts-backend-api-2.calmforest-0de75ae2.centralus.azurecontainerapps.io/gen_title/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
            body: JSON.stringify({ category: category, ideaOrTopic: ideaOrTopic, user: user.uid }),
        })
            .then(response => response.json())
            .then(data => {
                // Parse the 'title' field from the string to an object
                const titleObj = JSON.parse(data.title);

                // Map the parsed titles to your desired format
                const fetchedTitles = Object.values(titleObj).map((title, index) => ({
                    id: index + 1,
                    title: title,
                }));

                setTitles(fetchedTitles);
                setIsLoading(false); // Stop loading
            })

    };




    // Function to toggle edit mode and initialize editing state
    const toggleEdit = (id, currentText, type) => {
        setEditingId({ id, type }); // Store both id and type
        setEditedText(currentText); // Pre-fill the input with the current text
    };

    // Function to save the edited title and exit edit mode
    // Update the saveEdit function to handle both titles and paragraphs
    const saveEdit = () => {
        if (editingId.type === 'title') {
            const updatedTitles = titles.map(title => {
                if (title.id === editingId.id) {
                    return { ...title, title: editedText };
                }
                return title;
            });
            setTitles(updatedTitles);
        } else if (editingId.type === 'paragraph') {
            const updatedParagraphs = paragraphs.map(paragraph => {
                if (paragraph.id === editingId.id) {
                    return { ...paragraph, paragraph: editedText };
                }
                return paragraph;
            });
            setParagrpahs(updatedParagraphs);
        }
        setEditingId(null); // Exit editing mode
    };




    const handleSelectAndGenerateContent = (title) => {
        // console.log("Selected Title:", title); // Logging the selected title
        setIsLoadingParagprah(true); // Indicate loading state

        // Adjust your API endpoint and method as necessary
        fetch('https://shorts-backend-api-2.calmforest-0de75ae2.centralus.azurecontainerapps.io/gen_content/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
            body: JSON.stringify({ category: category, ideaOrTopic: ideaOrTopic, title: title, user: user.uid }),
        })
            .then(response => response.json())
            .then(data => {
                // Split the content by newline characters to separate paragraphs
                const paragraphsArray = data.content.split("\n\n");
                // Get only the first two paragraphs
                const firstTwoParagraphs = paragraphsArray.slice(0, 2);
                // Map over the paragraphs to create the desired structure
                const fetchedParagraphs = firstTwoParagraphs.map((paragraph, index) => ({
                    id: index + 1,
                    paragraph: paragraph, // Each paragraph is now directly used here
                    custom: false, // Each paragraph is now directly used here
                    customPrompt: '', // Each paragraph is now directly used here
                }));

                setParagrpahs(fetchedParagraphs); // Assuming you're updating to use a state that stores paragraphs
                // console.log('fetchedParagraphs', fetchedParagraphs);
                setIsLoadingParagprah(false); // Reset loading state
            })
            .catch(error => {
                console.error('Error fetching content:', error);
                setInputError('Failed to load content. Please try again.');
                setIsLoadingParagprah(false); // Reset loading state in case of error
            });
    };



    const isPayloadComplete = () => {
        // Check if a title is selected

        // console.log("isPayloadComplete", !titles[selectedId], selectedId)
        if (!titles[selectedId - 1]) return false;

        // Check if there are paragraphs and each paragraph has an imageUrl
        const allImagesGenerated = paragraphs.every(paragraph => paragraph.imageUrl);

        return allImagesGenerated;
    };




    const startCountdown = () => {
        // Clear existing countdown if any
        if (countdownIntervalId !== null) clearInterval(countdownIntervalId);

        setCountdown(120); // Reset countdown to 2 minutes
        const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(intervalId); // Stop the countdown when it reaches 0
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000); // Decrease every second

        setCountdownIntervalId(intervalId); // Store interval ID for later cleanup
    };


    const assembleAndGenerateVideo = async () => {


        if (!isPayloadComplete()) {
            console.error('Payload is not complete.');
            return;
        }

        setIsGeneratingVideo(true); // Start generating video
        startCountdown(); // Start the countdown when video generation starts
        const payload = {
            voice: voiceOption,
            title: titles[selectedId - 1].title,
            user: user.uid,
            segments: paragraphs
                .filter(paragraph => paragraph.imageUrl)
                .map(paragraph => ({
                    text: paragraph.paragraph,
                    imageUrl: paragraph.imageUrl,
                })),
        };



        // console.log('Payload for Video Generation:', payload);

        const videoGenerationEndpoint = 'https://shorts-backend-api-2.calmforest-0de75ae2.centralus.azurecontainerapps.io/gen_video/';
        try {
            const response = await fetch(videoGenerationEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) throw new Error('Video generation failed');



            const data = await response.json();
            // console.log('Video generation successful:', data);

            // Trigger video download
            if (data.videoDownloadUrl) {


                // Store record in Firestore
                const videoRecord = {
                    downloadUrl: data.videoDownloadUrl,
                    title: titles[selectedId - 1].title,
                    timestamp: serverTimestamp(),
                    author_Id: user.uid,
                    description: paragraphs
                        .filter(paragraph => paragraph.imageUrl)
                        .map(paragraph => ({
                            text: paragraph.paragraph
                        })),
                    // Add any other relevant information you want to store
                };

                // Add the record to Firestore
                await addVideoRecordToFirestore(videoRecord);
                // Create new document in "events" collection
                const eventRecord = {
                    event_type: 'generate_youtube_download',
                    created_at: serverTimestamp(),
                    user: user.uid
                };
                // console.log(serverTimestamp())
                // console.log(eventRecord)


                // Add the event record to Firestore
                await addEventRecordToFirestore(eventRecord);


                const a = document.createElement('a');
                a.href = data.videoDownloadUrl;
                a.download = 'GeneratedVideo.mp4'; // Optional: You can set the filename here
                document.body.appendChild(a); // Append the anchor to body
                a.click(); // Simulate click on the anchor
                document.body.removeChild(a); // Clean up


            }

        } catch (error) {
            console.error('Error during video generation:', error);

        } finally {
            setIsGeneratingVideo(false); // Reset video generation status
        }
    };



    // Function to add video record to Firestore
    const addVideoRecordToFirestore = async (videoRecord) => {
        try {
            // Get a reference to the 'videos' collection in Firestore
            const videosCollectionRef = collection(db, 'videos');

            // Add the video record to the collection
            await addDoc(videosCollectionRef, videoRecord);
        } catch (error) {
            console.error('Error adding video record to Firestore:', error);
        }
    };


    const addEventRecordToFirestore = async (eventRecord) => {
        try {
            // Get a reference to the 'videos' collection in Firestore
            const eventCollectionRef = collection(db, 'events');
            // Add the video record to the collection
            await addDoc(eventCollectionRef, eventRecord);

        } catch (error) {
            console.error('Error adding event record to Firestore: ', error);
        }
    }





    return (
        <div className="App container py-5">

            {isGeneratingVideo && (
                <div className=" overlay">
                    <h3> {isGeneratingVideo ? `Generating Video... Please don't close this page. ETA :  ${formatCountdown()}` : 'Generate Video'}</h3>
                </div>
            )}

            <h2 className="mb-4 mt-4">
                Craft Captivating Short Videos for YouTube & TikTok with AI</h2>

            <hr />

            <div className="container">
                <div className="row mb-4 mt-4">
                    <div className="col-md-4">
                        <label htmlFor="categorySelect" className="form-label">Target Category</label>
                        <select className="form-select" id="categorySelect" required value={category} onChange={(e) => setCategory(e.target.value)}>
                            <option value="">Select a category</option>
                            {categories.map((cat, index) => (
                                <option key={index} value={cat}>{cat}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="ideaOrTopic" className="form-label">Whats your idea or topic?</label>
                        <input
                            type="text"
                            className="form-control"
                            id="ideaOrTopic"
                            value={ideaOrTopic}
                            onChange={(e) => setIdeaOrTopic(e.target.value)}
                            maxLength={50}
                            placeholder="Enter your idea or topic"
                            aria-label="IdeaOrTopic"
                        />
                    </div>
                    <div className="col-md-4">

                        <div>
                            {user?.uid ? (
                                <>

                                    {balance > 0.036 ? (
                                        <button className="btn btn-success mt-4" onClick={handleGenerateTitles} disabled={isLoading || !category}>

                                            {isLoading ? 'Loading…' : 'Generate Video Titles'}
                                        </button>
                                    ) : (
                                        <>
                                            <div>You've run out of balance.</div>
                                            <a href="https://pay.doctran.ai/b/9AQ6r79cg8CS8b6dQQ" target="_blank" rel="noopener noreferrer" className="btn btn-primary my-2 my-sm-0 btn-sm me-2">
                                                Top Up
                                            </a>

                                        </>
                                    )}
                                </>
                            ) : (
                                null
                            )}
                        </div>

                    </div>
                </div>

            </div>

            {inputError && <div className="alert alert-danger" role="alert">{inputError}</div>}
            {
                isLoading ? (

                    <>
                        <p className='mt-4 mb-4'>Generating video title based on the selected category & topic / idea. Please wait a moment...</p>

                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>

                    </>
                ) : (

                    <div>



                        {
                            titles && titles.length > 0 && (
                                <h5 className='mb-2 mt-2'>Select Video Title</h5>
                            )
                        }


                        <ul className="list-group">
                            {titles.map((title) => (
                                <li
                                    key={title.id}
                                    className={`list-group-item d-flex justify-content-between align-items-center ${selectedId === title.id ? 'bg-success text-white' : ''}`}
                                >
                                    {editingId && editingId.type === 'title' && editingId.id === title.id ? (
                                        <>
                                            <input
                                                className="form-control me-2"
                                                type="text"
                                                value={editedText}
                                                onPaste={(e) => e.preventDefault()}
                                                maxLength={100}
                                                onChange={(e) => setEditedText(e.target.value)}
                                            />
                                            <button className="btn btn-primary btn-sm me-2" onClick={() => saveEdit(title.id)}>Done</button>
                                        </>
                                    ) : (
                                        <>
                                            {title.title}
                                            {/* Conditional rendering based on whether the item is selected */}
                                            {selectedId !== title.id ? (
                                                <div>


                                                    {user?.uid ? (
                                                        <>

                                                            {balance > 0.36 ? (

                                                                <>
                                                                    <button
                                                                        className="btn btn-outline-secondary btn-sm me-2 mb-2"
                                                                        onClick={() => toggleEdit(title.id, title.title, 'title')}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-success btn-sm mb-2"
                                                                        onClick={() => {
                                                                            handleSelectAndGenerateContent(title.title);
                                                                            setSelectedId(title.id); // Update the selectedId when 'Select' is clicked

                                                                        }}
                                                                    >
                                                                        Select
                                                                    </button></>
                                                            ) : (
                                                                <>

                                                                    <a href="https://pay.doctran.ai/b/9AQ6r79cg8CS8b6dQQ" target="_blank" rel="noopener noreferrer" className="btn btn-primary my-2 my-sm-0 btn-sm me-2">
                                                                        Top Up
                                                                    </a>

                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        null
                                                    )}



                                                </div>
                                            ) : (
                                                // Display a check icon when the item is selected
                                                <span style={{ marginLeft: '10px', color: 'white' }}>&#10003;</span> // Unicode checkmark
                                            )}
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>





                        {isLoadingParagprah ? (

                            <>
                                <p className='mt-4 mb-4'>Generating video script based on the selected category & topic / idea and title. Please wait a moment...</p>
                                <div className="d-flex justify-content-center mb-2 mt-4">

                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </>

                        ) : (

                            <div>
                                {
                                    selectedId && (
                                        <h5 className='mb-2 mt-4'>Video Script</h5>
                                    )
                                }

                                <ul className="list-group">
                                    {paragraphs.map((paragraph) => (
                                        <li key={paragraph.id} className="list-group-item">
                                            <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
                                                <div style={{ flex: 7 }}>
                                                    {editingId && editingId.type === 'paragraph' && editingId.id === paragraph.id ? (
                                                        <>
                                                            <textarea
                                                                className="form-control mb-2"
                                                                value={editedText}
                                                                onChange={(e) => setEditedText(e.target.value)}
                                                                maxLength={300}
                                                                style={{
                                                                    height: '100px',
                                                                    overflowY: 'auto', // Enable vertical scroll when content exceeds height
                                                                }}
                                                            />

                                                            {editedText.length > 300 && (
                                                                <div className="text-warning mb-2">Please reduce the text to 300 characters.</div>
                                                            )}
                                                            <button className="btn btn-primary btn-sm" onClick={() => saveEdit(paragraph.id)}>Done</button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <p className='text-start'>{paragraph.paragraph}</p>

                                                            <div className="d-flex justify-content-between align-items-center">



                                                                <button className="btn btn-outline-secondary btn-sm me-2" onClick={() => toggleEdit(paragraph.id, paragraph.paragraph, 'paragraph')}>
                                                                    Edit
                                                                </button>


                                                                {user?.uid ? (
                                                                    <>

                                                                        {balance > 0.2 ? (

                                                                            <>
                                                                                <button
                                                                                    className="btn btn-success btn-sm"
                                                                                    onClick={() => handleGenerateGraphic(paragraph.id, paragraph.paragraph, paragraph.custom, paragraph.customPrompt)}
                                                                                    disabled={paragraph.isLoading}
                                                                                >
                                                                                    Generate Graphic
                                                                                </button>


                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div className=''>You've run out of balance.</div>
                                                                                <a href="https://pay.doctran.ai/b/9AQ6r79cg8CS8b6dQQ" target="_blank" rel="noopener noreferrer" className="btn btn-primary my-2 my-sm-0 btn-sm me-2">
                                                                                    Top Up
                                                                                </a>

                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    null
                                                                )}


                                                            </div>
                                                            <div className="mt-2">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={paragraph.custom}
                                                                    maxLength={100}
                                                                    onChange={() => handleCustomChange(paragraph.id)}
                                                                />
                                                                <label className="ms-2">

                                                                    Custom graphic instructions.</label>
                                                            </div>

                                                            {paragraph.custom && // Show input field only if custom is checked
                                                                <div className="mt-2">

                                                                    <input
                                                                        type="text"
                                                                        value={paragraph.customPrompt}
                                                                        onChange={(e) => handleCustomPromptChange(paragraph.id, e.target.value)}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                            }
                                                        </>


                                                    )}
                                                </div>
                                                <div className="d-flex align-items-center justify-content-center" style={{ flex: 3 }}>
                                                    <div style={{
                                                        width: '200px',
                                                        height: '347.89px',
                                                        backgroundColor: '#eee',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginTop: '10px',
                                                        marginBottom: '20px',
                                                        borderRadius: '22px',
                                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                        position: 'relative',
                                                    }}>
                                                        {paragraph.isLoading ? (
                                                            <div className="spinner-border text-primary" role="status" style={{ position: 'absolute' }}>
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        ) : paragraph.imageUrl ? (
                                                            <img src={paragraph.imageUrl} alt="Generated Graphic" style={{ width: '100%', height: 'auto', borderRadius: '22px' }} />
                                                        ) : "Image Placeholder"}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>



                                <div className="container mt-4 mb-4">


                                    {isPayloadComplete() && (
                                        <>
                                            <div className='mt-4 mb-4'>
                                                <label className='me-2'>
                                                    <input
                                                        className='me-2'
                                                        type="radio"
                                                        value="onyx"
                                                        checked={voiceOption === 'onyx'}
                                                        onChange={handleVoiceOptionChange}
                                                    />
                                                    Male Voice
                                                </label>
                                                <label>
                                                    <input
                                                        className='me-2'
                                                        type="radio"
                                                        value="nova"
                                                        checked={voiceOption === 'nova'}
                                                        onChange={handleVoiceOptionChange}
                                                    />
                                                    Female Voice
                                                </label>
                                            </div>




                                            {user?.uid ? (
                                                <>

                                                    {balance > 0 ? (

                                                        <>
                                                            <button
                                                                className="btn btn-success"
                                                                onClick={assembleAndGenerateVideo}
                                                                disabled={isGeneratingVideo}
                                                            >
                                                                {isGeneratingVideo ? `Generating Video... ETA :  ${formatCountdown()}` : 'Generate Video'}
                                                            </button>


                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className=''>You've run out of balance.</div>
                                                            <a href="https://pay.doctran.ai/b/9AQ6r79cg8CS8b6dQQ" target="_blank" rel="noopener noreferrer" className="btn btn-primary my-2 my-sm-0 btn-sm me-2">
                                                                Top Up
                                                            </a>

                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                null
                                            )}




                                        </>
                                    )}

                                    {!isPayloadComplete() && (
                                        <>
                                            <div className='mt-4 mb-4'>
                                                <label className='me-2'>
                                                    <input
                                                        className='me-2'
                                                        type="radio"
                                                        value="onyx"
                                                        checked={voiceOption === 'onyx'}
                                                        onChange={handleVoiceOptionChange}
                                                    />
                                                    Male Voice
                                                </label>
                                                <label>
                                                    <input
                                                        className='me-2'
                                                        type="radio"
                                                        value="nova"
                                                        checked={voiceOption === 'nova'}
                                                        onChange={handleVoiceOptionChange}
                                                    />
                                                    Female Voice
                                                </label>
                                            </div>

                                            <button
                                                className="btn btn-success mb-4"
                                                onClick={assembleAndGenerateVideo}
                                                disabled={true} // Always disabled
                                            >
                                                <Youtube /> Generate Video
                                            </button>
                                        </>

                                    )}

                                </div>




                            </div>


                        )}
                    </div>



                )
            }


            <div className="container mt-4 mb-4">
                <hr />
                <h5 className='mt-4 mb-4 text-right'>Download Videos </h5>
                {/* <p> If you are on a mobile phone, please note that downloading videos directly to a mobile device can be challenging. Consider using a desktop for smoother downloads, as video links may expire.
                </p> */}
                <ul className="list-group">
                    {videos.map((video, index) => (
                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                            <div>
                                <p className="mb-1">
                                    {video.title}{' '}
                                    <span className="copy-icon" onClick={() => copyText(video.title)} style={{ cursor: 'pointer' }}>
                                        <i className="far fa-copy"></i>
                                    </span>
                                    <small> ( {video.timestamp?.toLocaleString()} )</small>
                                </p>
                            </div>
                            <div className="d-flex align-items-center">
                                {video.description && video.description.length === 2 && (
                                    <button className="btn btn-outline-dark btn-sm me-2" onClick={() => copyText(`${video.title}\n\n${video.description[0].text}\n\n${video.description[1].text}`)} style={{ cursor: 'pointer' }}>
                                        <Copy /> Script
                                    </button>
                                )}
                                <a href={video.downloadUrl} className="btn btn-primary btn-sm"><Download />  Download</a>
                            </div>
                        </li>
                    ))}
                </ul>

            </div>
        </div >
    );
}

export default Studio;
