import React, { useState } from 'react';
import { UserAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { Form, Button, Alert } from 'react-bootstrap';

import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

import { Link, useLocation } from 'react-router-dom';

const Feedback = () => {
    const { user } = UserAuth();
    const [feedbackType, setFeedbackType] = useState('');
    const [feedbackDetails, setFeedbackDetails] = useState('');
    const [showThanks, setShowThanks] = useState(false);

    const handleFeedbackTypeChange = (event) => {
        setFeedbackType(event.target.value);
    };

    const handleFeedbackDetailsChange = (event) => {
        setFeedbackDetails(event.target.value);
    };

    const submitFeedback = async () => {
        try {
            if (!feedbackType || !feedbackDetails) {
                alert('Please fill out all fields.');
                return;
            }

            // Add feedback to Firestore
            const feedbackCollectionRef = collection(db, 'feedback');
            await addDoc(feedbackCollectionRef, {
                userId: user.uid,
                type: feedbackType,
                details: feedbackDetails,
                timestamp: serverTimestamp()
            });

            // Reset form
            setFeedbackType('');
            setFeedbackDetails('');
            setShowThanks(true);
        } catch (error) {
            console.error('Error submitting feedback: ', error);
            alert('An error occurred while submitting feedback. Please try again later.');
        }
    };

    return (
        <div className="container">
            <h1 className='mt-4 mb-4' >Feedback</h1>
            <p>
                We value your opinion and appreciate you taking the time to share your feedback with us. Your input is invaluable as it helps us understand how we can better serve you. Whether you have suggestions for improvements, encountered a bug, or simply want to provide general feedback, we are eager to hear from you. Your feedback enables us to continually enhance our products and services to meet your needs and expectations. Thank you for helping us improve!
            </p>
            <Form>
                <Form.Group controlId="feedbackType">
                    <Form.Label>Feedback Type</Form.Label>
                    <Form.Control className='mb-4' as="select" value={feedbackType} onChange={handleFeedbackTypeChange}>
                        <option value="">Select...</option>
                        <option value="general">General Feedback</option>
                        <option value="bug">Bug Report</option>
                        <option value="feature">Feature Request</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="feedbackDetails" className='mb-4' >
                    <Form.Label>Feedback Details</Form.Label>
                    <Form.Control as="textarea" rows={3} maxLength={500} value={feedbackDetails} onChange={handleFeedbackDetailsChange} />
                </Form.Group>
                <Button variant="primary" onClick={submitFeedback}>Submit Feedback</Button>
            </Form>
            {showThanks && (
                <Alert variant="success" className="mt-3">
                    Thank you for your feedback! Someone will get in touch with you soon.
                </Alert>
            )}
        </div>
    );
};

export default Feedback;
