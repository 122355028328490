import Navbar from './components/Navbar';
import Protected from './components/Protected';
import { AuthContextProvider } from './context/AuthContext';
import Signin from './pages/Signin';
import Studio from './pages/Studio';
import Speech from './pages/Speech';
import Feedback from './pages/Feedback';
import Home from './pages/Home';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Updated import
import { auth } from './firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/minty/bootstrap.min.css'; // Replace 'minty' with your desired Bootswatch theme
import './App.css'; // Import CSS file for styling
import ReactGA from "react-ga4";
import TermsOfUse from './pages/TermsofUse';
ReactGA.initialize("RLXHND0TM6");

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router> {/* Wrap routes with Router */}
      <AuthContextProvider>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/signin' element={<Signin />} />
          <Route
            path='/studio'
            element={
              <Protected>
                <div className="container-fluid">
                  <main role="main" className=" ">
                    <Studio></Studio>
                  </main>
                </div>
              </Protected>
            }
          />

          <Route
            path='/text-to-speech'
            element={
              <Protected>
                <div className="container-fluid">
                  <main role="main" className=" ">
                    <Speech></Speech>
                  </main>
                </div>
              </Protected>
            }
          />


          <Route
            path='/feedback'
            element={
              <Protected>
                <div className="container-fluid">
                  <main role="main" className=" ">
                    <Feedback></Feedback>
                  </main>
                </div>
              </Protected>
            }
          />

          <Route
            path='/terms'
            element={
              <Protected>
                <div className="container-fluid">
                  <main role="main" className=" ">
                    <TermsOfUse></TermsOfUse>
                  </main>
                </div>
              </Protected>
            }
          />
        </Routes>


      </AuthContextProvider>
    </Router>
  );
}

export default App;
