import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Home = () => {

  return (
    <div className="container">
      <header className="text-center mt-5 bg-light rounded p-4">
        <div className="mb-5">
          <h1 className="display-4">AI-Generated Voiceovers</h1>
          <p className="lead">Enhance your videos with professional-quality AI-generated voiceovers. Our advanced Text to Speech services will give your content a unique edge with natural-sounding, customizable voices.</p>
          <Link to="/text-to-speech">
            <Button variant="primary" className="btn-lg mt-3">Generate AI Voiceovers Now!</Button>
          </Link>
        </div>

        <div>
          <h1 className="display-4">Revolutionize Short Video Creation with AI</h1>
          <p className="lead">Create captivating short videos for YouTube Shorts and TikTok. Our AI-driven video generator allows you to produce stunning, engaging content effortlessly.</p>
          <p className="lead">Ready to elevate your short videos? Sign up today to access our innovative platform for free and experience the power of AI in video creation!</p>
          <Link to="/studio">
            <Button variant="primary" className="btn-lg mt-3">Generate AI Videos Now!</Button>
          </Link>
        </div>
      </header>
      <section className="mt-5">
        <div className="row mt-4">
          <div className="col-md-4">
            <h3>1: Choose Your Voice and Category</h3>
            <p>Select from a variety of AI-generated voices and explore popular categories or input your own topic to generate innovative video ideas based on YouTube best practices.</p>
          </div>
          <div className="col-md-4">
            <h3>2: Personalize Your Video</h3>
            <p>Input your script and customize the voiceover with tone, pace, and pronunciation. Customize your video with AI-generated graphics and voiceovers to reflect your unique style and brand identity.</p>
          </div>
          <div className="col-md-4">
            <h3>3: Download and Share</h3>
            <p>Once your video is ready, download it and effortlessly share it on YouTube, TikTok, or any platform of your choice. Watch your audience engagement soar!</p>
          </div>
        </div>
      </section>

      <section className="mt-5 mb-4">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">AI-Powered Text to Speech</h3>
                <p className="card-text">Transform any text into realistic speech with our advanced AI technology. Perfect for creating voiceovers that captivate and engage your audience.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Professional AI Voiceovers</h3>
                <p className="card-text">Choose from male or female AI-generated voiceovers to add professional narration to your videos. Get ready-to-use voiceovers in seconds!</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">YouTube Shorts & TikTok Compatibility</h3>
                <p className="card-text">Our videos and voiceovers are optimized for YouTube Shorts and TikTok, ensuring seamless integration with these popular platforms. Gain views and subscribers quickly with our AI-powered content!</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 mb-5">
        <div className="container">
          <h2 className="text-center mb-4">Success Story: Pets Home App</h2>
          <p className="lead text-center">
            Discover how Pets Home App leveraged our AI-powered platform to create engaging content and skyrocket their views and subscribers.
          </p>
          <p>
            Pets Home App, a popular pet rehoming service, wanted to take their content creation to the next level. They turned to our platform for assistance, and the results were astounding.
          </p>
          <p>
            By utilizing our AI-driven tools to generate captivating video titles, stunning graphics, and professional voiceovers, Pets Home App was able to create high-quality videos at scale. These videos resonated with their audience, leading to increased engagement, higher view counts, and a surge in subscribers.
          </p>
          <p>
            As a result of their collaboration with us, Pets Home App experienced a significant boost in their app downloads, further establishing themselves as a leader in the pet content niche.
          </p>
          <div className="text-center mt-4">
            <a href="https://www.youtube.com/channel/UCTjh3nmz0cVGaX0MnHl6elA" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-lg">Visit Pets Home App Channel</a>
          </div>
        </div>
      </section>

      <section className="mt-5 mb-5">
        <div className="container">
          <h2 className="text-center mb-4">How AI Can Boost Your Channel</h2>
          <p className="lead text-center">
            Unlock the potential of AI-generated content to drive growth and engagement on your YouTube channel.
          </p>
          <p>
            AI-powered content generation offers a game-changing solution for content creators looking to enhance their reach and impact. By leveraging advanced algorithms, gen AI enables you to create compelling video titles, eye-catching graphics, and professional voiceovers in a matter of minutes.
          </p>
          <p>
            The effectiveness of AI-generated content in driving subscriber growth and increasing views has been proven time and time again. Channels across various niches have experienced remarkable results, with AI-generated videos consistently outperforming traditional content in terms of engagement and audience retention.
          </p>
          <p>
            Whether you're a seasoned creator or just starting out, integrating AI into your content creation process can provide you with a competitive edge, helping you attract more subscribers, boost views, and achieve your channel's full potential.
          </p>
        </div>
      </section>

      <section className="mt-5">
        <div className="container">
          <h2 className="text-center mb-4">User Reviews</h2>
          <div className="row">
            <div className="col-md-6">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">John Doe</h5>
                  <p className="card-text">"I've been using this platform for a few months now, and I'm blown away by the results! The AI-generated content has helped me save time and produce high-quality videos consistently. My channel's views and subscriber count have increased significantly since I started using this service. Highly recommend!"</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">Emily Smith</h5>
                  <p className="card-text">"As a small YouTuber, I was struggling to keep up with the demand for fresh content. Thanks to this platform, I've been able to produce engaging videos more efficiently than ever before. While there's room for improvement in some areas, overall, I'm impressed with the results I've seen so far."</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">David Johnson</h5>
                  <p className="card-text">"This platform has revolutionized the way I create content for my channel. The AI-generated graphics and voiceovers are top-notch, and the video ideas generator has been a lifesaver when I'm stuck for inspiration. Thanks to this platform, I've seen a significant increase in both views and subscribers. Couldn't be happier!"</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">Sarah Lee</h5>
                  <p className="card-text">"I've been using this platform for a few weeks now, and I'm already seeing positive results. The AI-generated content is impressive, and it's helped me attract a wider audience to my channel. The only downside is that I wish there were more customization options available. Overall, though, I'm thrilled with the progress I've made."</p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title">Michael Brown</h5>
                  <p className="card-text">"I've tried several content creation platforms in the past, but none have come close to this one. The AI-powered features are incredibly intuitive and have allowed me to produce professional-quality videos with ease. My channel's growth has exceeded my expectations, and I have this platform to thank for it. Highly recommend to any YouTuber looking to take their channel to the next level!"</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="mt-5 text-center">
        <p>&copy; 2024 voicedone.ai All rights reserved.</p>
        <Link to="/speech-to-text">Speech to Text</Link>
      </footer>
    </div>
  );
};

export default Home;
