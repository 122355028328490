import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, Alert, ProgressBar } from 'react-bootstrap';
import { UserAuth } from '../context/AuthContext';
import { collection, addDoc, onSnapshot, query, where, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import './Speech.css'; // Import custom CSS for styling

function Speech() {
    const { user, userData } = UserAuth();
    const [text, setText] = useState('');
    const [voice, setVoice] = useState('alloy');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [records, setRecords] = useState([]);
    const [isCreating, setIsCreating] = useState(false);

    // Check if userData exists and has balance
    const balance = userData && userData.balance ? userData.balance : 0;


    useEffect(() => {
        if (user) {
            const q = query(
                collection(db, 'speech'),
                where('userId', '==', user.uid),
                where('deleted', '==', false),
                orderBy('timestamp', 'desc')
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const userRecords = [];
                querySnapshot.forEach((doc) => {
                    userRecords.push({ id: doc.id, ...doc.data() });
                });
                setRecords(userRecords);
            });

            return () => unsubscribe();
        }
    }, [user]);

    const handleTextChange = (e) => {
        const inputText = e.target.value;
        const words = inputText.split(/\s+/).filter(word => word.length > 0);
        if (words.length <= 500) {
            setText(inputText);
        }
    };

    const handleSaveAndGenerate = async () => {
        setError('');
        setSuccess('');
        setIsLoading(true);

        const words = text.split(/\s+/).filter(word => word.length > 0);
        if (words.length === 0) {
            setError('Please enter some text for the voiceover.');
            setIsLoading(false);
            return;
        }
        if (words.length > 500) {
            setError('Text exceeds 500 words limit.');
            setIsLoading(false);
            return;
        }

        try {
            await addDoc(collection(db, 'speech'), {
                userId: user.uid,
                input: text.trim(),
                voice,
                timestamp: Math.floor(Date.now() / 1000), // Unix timestamp
                status: 0, // Initial status
                errorMessage: '',
                deleted: false
            });

            setSuccess('Voice over request submitted successfully!');
            setText('');
            setVoice('alloy');
            setIsCreating(false);
        } catch (error) {
            setError('Failed to submit voice over request.');
        }

        setIsLoading(false);
    };

    const handleDelete = async (recordId) => {
        try {
            const docRef = doc(db, 'speech', recordId);
            await updateDoc(docRef, { deleted: true });
        } catch (error) {
            setError('Failed to delete the record.');
        }
    };



    const handleDownload = async (url, id) => {
        try {
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${id}.mp3`;  // Use the record ID as the file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('There was an error with the download:', error);
        }
    };



    // Inside your JSX where you render the download button



    const anyInProgress = records.some(record => record.status === 0);

    return (
        <div className="container py-5">

            <h2 className="mb-4 mt-4">Effortless Voiceover Generator</h2>
            <hr />
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            {!isCreating && !anyInProgress && (
                <Button variant="primary" onClick={() => setIsCreating(true)} className="mb-4" size="sm">
                    Create New Voice Over
                </Button>
            )}
            {isCreating && (
                <Form>
                    <Form.Group controlId="voiceoverText">
                        <Form.Label>Text Input (Max 500 words)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={6}
                            value={text}
                            onChange={handleTextChange}
                            placeholder="Enter your text here..."
                            disabled={isLoading}
                        />
                        <Form.Text className="text-muted">
                            {text.split(/\s+/).filter(word => word.length > 0).length} / 500 words
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="voiceSelection" className="mt-3 text-center">
                        <p><small>Voice options</small></p>
                        <div className="radio-buttons">
                            {['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'].map((voiceOption) => (
                                <Form.Check
                                    type="radio"
                                    label={voiceOption.charAt(0).toUpperCase() + voiceOption.slice(1)}
                                    name="voice"
                                    id={voiceOption + "Voice"}
                                    value={voiceOption}
                                    checked={voice === voiceOption}
                                    onChange={(e) => setVoice(e.target.value)}
                                    disabled={isLoading}
                                    className="mx-2"
                                    key={voiceOption}
                                />
                            ))}
                        </div>
                    </Form.Group>

                    <div className='text-center'>


                        {user?.uid ? (
                            <>

                                {balance > 0.0 ? (
                                    <Button
                                        variant="primary"
                                        className="mt-4 text-center"
                                        onClick={handleSaveAndGenerate}
                                        disabled={isLoading}
                                        block
                                    >
                                        {isLoading ? <Spinner animation="border" size="sm" /> : 'Generate Voice Over'}
                                    </Button>
                                ) : (
                                    <>
                                        <div>You've run out of balance.</div>
                                        <a href="https://pay.doctran.ai/b/9AQ6r79cg8CS8b6dQQ" target="_blank" rel="noopener noreferrer" className="btn btn-primary my-2 my-sm-0 btn-sm me-2">
                                            Top Up
                                        </a>

                                    </>
                                )}
                            </>
                        ) : (
                            null
                        )}




                    </div>

                </Form>
            )}
            <hr />
            <h3>Your Voiceover Records</h3>
            {records.length > 0 ? (
                <div className="record-list">
                    {records.map(record => (
                        <div key={record.id} className="record-item">
                            {record.status === -1 && (
                                <p><strong>Error:</strong> {record.error.message}</p>
                            )}
                            {record.status === 0 && (
                                <ProgressBar animated now={100} label="In Progress" />
                            )}

                            {record.status === 1 && (
                                <div>

                                    <audio controls>
                                        <source src={record.downloadUrl} type="audio/mp3" />
                                        Your browser does not support the audio element.
                                    </audio>

                                </div>
                            )}


                            <p>  {record.voice} : {record.input.slice(0, 100)}...</p>
                            <p> <small>{new Date(record.timestamp * 1000).toLocaleString()}</small></p>

                            <hr />
                            <div className="d-flex justify-content-between">

                                <Button
                                    variant="danger"
                                    onClick={() => handleDelete(record.id)}
                                    size="sm"
                                >
                                    Delete
                                </Button>


                                {record.status === 1 && (
                                    <Button
                                        variant="success"
                                        onClick={() => handleDownload(record.downloadUrl, record.id)}
                                        size="sm"
                                    >
                                        Download
                                    </Button>
                                )}
                            </div>

                        </div>
                    ))}
                </div>
            ) : (
                <p>No records found.</p>
            )}
        </div>
    );
}

export default Speech;
