import React from 'react';

const TermsOfUse = () => {
    return (
        <div className="container">
            <h1 className='mt-4 mb-4'>Terms of Use</h1>
            <p>
                By using our services, you agree to abide by the following terms and conditions:
            </p>
            <ul>
                <li><strong>Top-up Balance:</strong> The balance you top up is not refundable. You pay for the services you use. Once the balance is deducted for a service, it cannot be refunded.</li>
                <li><strong>AI Generated Content:</strong> Our AI technology provides results based on the input provided, however, it may not always generate the expected or desired outcome. For example, generated graphics or content may not be to your liking. In such cases, you are still liable to pay for the services rendered.</li>
                <li><strong>Specific Requests:</strong> To avoid dissatisfaction with our services, we encourage you to be specific about your requirements. Clear instructions help us deliver tailored results that meet your expectations.</li>
                <li><strong>Disclaimer:</strong> Our services are provided on an "as is" and "as available" basis. We do not guarantee the accuracy, completeness, or reliability of our services. We shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to the use or inability to use our services.</li>
            </ul>
            <p>
                Please read these terms carefully before using our services. By accessing or using our services, you agree to be bound by these terms.
            </p>
        </div>
    );
};

export default TermsOfUse;
