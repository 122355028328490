import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import Firebase Storage

// Your Firebase config object, which you'd get from your Firebase project settings.
const firebaseConfig = {
    apiKey: "AIzaSyCwOA4kzWj23EExvteVj6ca2wzaceYqazU",
    authDomain: "shorts-f3cd2.firebaseapp.com",
    projectId: "shorts-f3cd2",
    storageBucket: "shorts-f3cd2.appspot.com",
    messagingSenderId: "815421446291",
    appId: "1:815421446291:web:822f3429bd0aeaa2b31566",
    measurementId: "G-0MNGDKQYMS"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Firebase Storage

export { auth, app, db, firebaseConfig, storage };


