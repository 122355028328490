import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { Navbar, Nav, Container } from 'react-bootstrap';

const Navigation = () => {
    const { user, logOut, userData } = UserAuth();
    const location = useLocation(); // Hook to access the current location

    // Check if userData exists and has balance
    const balance = userData && userData.balance ? userData.balance : 0;

    const handleSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Navbar bg="light" expand="lg" variant="light" sticky="top">
            <Container>
                <Navbar.Brand as={Link} to="/" className="mr-auto">
                    <img src="logo192.png" width="50" height="50" className="d-inline-block align-top mr-1" alt="" />
                    <small className='d-none d-sm-inline-block mt-3' style={{ fontSize: 12 }}>Effortless Video Creation</small>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to='/' className={location.pathname === '/' ? 'active' : ''}><i className="fa fa-home" aria-hidden="true"></i> Home</Nav.Link>



                        <Nav.Link as={Link} to='/studio' className={location.pathname === '/studio' ? 'active' : ''}><i className="fa fa-user" aria-hidden="true"></i> Shorts Studio</Nav.Link>
                        <Nav.Link as={Link} to='/text-to-speech' className={location.pathname === '/text-to-speech' ? 'active' : ''}><i className="fa fa-user" aria-hidden="true"></i> Text to Speech</Nav.Link>
                        <Nav.Link as={Link} to='/feedback' className={location.pathname === '/feedback' ? '' : ''}><i className="fa fa-help" aria-hidden="true"></i> Feedback</Nav.Link>
                        <Nav.Link as={Link} to='/terms' className={location.pathname === '/terms' ? '' : ''}><i className="fa fa-terms" aria-hidden="true"></i> Terms of use</Nav.Link>

                    </Nav>
                </Navbar.Collapse>

                <div className="ml-auto">
                    {user?.uid ? (
                        <>
                            <span className='me-2'>Balance: ${balance.toFixed(2)}</span>
                            {balance <= 1 && (
                                <a href="https://pay.doctran.ai/b/9AQ6r79cg8CS8b6dQQ" target="_blank" rel="noopener noreferrer" className="btn btn-primary my-2 my-sm-0 btn-sm me-2">
                                    Top Up
                                </a>
                            )}


                            <button className="btn btn-outline-dark my-2 my-sm-0 btn-sm" onClick={handleSignOut}>Logout</button>
                        </>
                    ) : (
                        <Link type="button" className="btn btn-outline-dark my-2 my-sm-0 btn-sm" to='/signin'>Sign in / Sign up</Link>
                    )}
                </div>
            </Container>
        </Navbar>
    );
};

export default Navigation;
